import { Inject, Injectable } from '@angular/core';
import { AuthConfig } from 'angular-oauth2-oidc';
import { APP_CONFIGURATION_TOKEN } from './injection-token';
import { FrontendAppConfiguration } from './services/configuration.services';

@Injectable({
    providedIn: 'root',
})
export class AuthConfigService {
    private _authConfig: AuthConfig = {
        issuer: null,
        redirectUri: null, // + '/#/sign-in', // + '/sign-in',
        responseType: 'code',
        requireHttps: false,
        clientId: 'app',
        scope: 'openid profile email offline_access roles',
        logoutUrl: `https://auth.em4cloud-dev.com/realms/{clientId}/protocol/openid-connect/logout`,
        useIdTokenHintForSilentRefresh: true,
    };

    constructor(@Inject(APP_CONFIGURATION_TOKEN) private appConfig: FrontendAppConfiguration) {}

    get authConfig(): AuthConfig {
        if (this._authConfig.issuer === null) {
            this._authConfig.issuer = this.appConfig.issuer;
            this._authConfig.redirectUri = this.appConfig.host;
        }
        return this._authConfig;
    }
}
